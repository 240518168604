@import '../app/shared/styles/mixins';
@import '../app/shared/styles/form';
@import '../app/shared/styles/button';
@import '../app/shared/styles/sticky-header';
@import '../app/shared/styles/sidenav';

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  button {
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 6px 10px !important;
  }
}

.expandable-bar {
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.alert {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-padding {
  padding: 0.75rem 1.25rem;
}

.mat-form-icon {
  color: var(--primary-color);
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.mat-mdc-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.tooltip-icon {
  color: var(--primary-color);
  font-size: 18px;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

ngx-global-contact-footer {
  display: block;
  padding: 40px 25px 25px;
}

.x-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.x-mobile-only {
  display: none;
}
.x-desktop-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .x-mobile-only {
    display: block;
  }
  .x-desktop-only {
    display: none;
  }
}

multi-locale-input {
  width: 100%;
}

.ng-invalid .mat-focused {
  color:var(--primary-color);
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #ccc;
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color);
}

.mat-icon > svg > path {
  fill: var(--primary-color) !important;
}

cdk-virtual-scroll-viewport::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: var(--sidebar-bg-color); /* Color of the scrollbar track */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: var(--primary-lighter-color); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); /* Color of the scrollbar thumb on hover */
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--primary-color) !important;
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #ccc;
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color);
}

.tree-node-margin {
  background-color: var(--primary-color) !important;
}

.tree-node:hover {
  background-color: var(--primary-lighter-color) !important;
}
