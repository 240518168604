.form-divider {
  max-width: 1024px;
  margin-left: 65px;
  margin-bottom: 20px;
}

.form-header {
  color: var(--primary-color);
  margin: 30px 0 15px;
  max-width: 1024px;
  border-bottom: solid 1px #dddddd;
  padding: 10px 5px;
  &.with-button {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    margin-right: 40px;
    h4 {
      flex-grow: 3;
      padding: 0;
      font-weight: bold;
      align-self: center;
    }
    .icon-btn {
      align-self: flex-end;
      flex-grow: 0;
      margin-right: 0 !important;
    }
    @include x-icon-button;
  }
}

.expandable-header {
  color: #461e7d;
  cursor: pointer;
  span {
    vertical-align: middle;
  }
  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

.mat-mdc-tooltip {
  font-size: 14px !important;
}

.mat-expansion-panel-header-description {
  flex-grow: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-disabled .mat-form-field-outline {
  background: #f6f6f6;
}

@media screen and (max-width: 768px) {
  .form-divider {
    margin-left: 0;
  }

  .form-header.with-button {
    margin-right: 0;
    .icon-btn {
      margin-right: 0 !important;
    }
  }
}
